.actionbarcolor {
  background-color: rgb(60, 60, 60);
}

div .dismiss {
  font-weight: bold;
  color: rgb(186, 97, 255);
  border-color: rgb(186, 97, 255);
  background-color: rgba(255, 255, 255, 0.95);
  width: 96px;
  &:hover {
    border-color: rgba(186, 97, 255, 0.5);
    background-color: rgba(255, 255, 255, 1);
    color: rgba(186, 97, 255, 0.5);
  }
}
div .confirm {
  font-weight: bold;
  background-color: rgb(186, 97, 255);
  width: 160px;
  /* width: 130px; */
  transition: width 0.1s ease;
  &:hover {
    background-color: rgba(186, 97, 255, 0.8);
    color: rgb(255, 255, 255);
  }
}
