.container {
  /* position: fixed; */

  text-align: center;

  z-index: 1;
  width: 100vw;
  box-sizing: border-box;

  /* background-color: rgb(45, 45, 45); */
  background: linear-gradient(to bottom, rgb(80, 64, 98), rgb(61, 55, 74));

  /* padding: 20px 0px; */
  /* border-bottom: 1px solid black; */
  /* box-shadow: 20px 40px 10px rgba(255, 0, 0, 0.5); */
  filter: drop-shadow(-10px 0px 8px rgba(0, 0, 0, 0.6));
  /* overflow: visible; */
  /* z-index: 100000; */
}

div .button {
  font-weight: bold;

  /* color: rgb(188, 188, 188); */
  color: rgb(230, 230, 230);
  height: 48px;
  min-width: 72px;
}
div .buttonActive {
  background-color: rgba(
    20,
    20,
    20,
    0.8
  ) !important; /* Change to your desired active background color */
}

div .popover {
  /* z-index: 10000; */
  margin-top: 1px;
  background-color: rgba(20, 20, 20, 0.8);
  backdrop-filter: saturate(100%) blur(6px);
  border: 1px solid rgb(64, 64, 64);
  /* border-bottom: 1px solid rgb(64, 64, 64);
  border-right: 1px solid rgb(64, 64, 64);
  border-left: 1px solid rgb(64, 64, 64); */
  /* border: 1px solid black; */
  padding-left: 8px;
  padding-right: 16px;
  /* width: 130px; */
}

div .item {
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  display: flex;
  gap: 8px;
  /* color: rgb(200, 200, 200); */
  color: rgb(240, 240, 240);
}

div .menulist {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.row {
  padding-left: 4px;
  padding-right: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
