.pagecontainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* padding: 40px; */
  /* frosty background */
  backdrop-filter: saturate(180%) blur(10px);
  padding: 40px;
}

.layout {
  height: 100%;
  align-items: start;
  width: 100%;
  /* flex: auto 300px 1fr; */
  display: grid;
  grid-template-columns: auto auto 1fr;
}

.content {
  height: 100%;
  /* width: 100%; */
  resize: none;
  background-color: rgba(255, 255, 255, 1);
}

.menubutton {
  color: rgba(255, 255, 255, 1);
}

.menucontaineropen {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 300px;
  transition: width 0.1s ease-out;
}
.menucontainerclosed {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 0px;
  transition: width 0.1s ease-out;
}
.menu {
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 1);
  width: 300px;
  /* height: 100%; */
  transition: width 0.1s ease-out;
  justify-content: start;
  align-items: start;
}
.menulabel {
  font-size: 18px;
  margin-bottom: 8px;
}
.menuitem {
  padding: 8px 16px;
  width: 100%;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 1);
  /* margin: 5px; */
  cursor: pointer;
  /* transition: background-color 0.1s ease-out; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div .menuitemcreate {
  padding: 8px 16px;
  /* width: 210px; */
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.5);
  /* border: 1px solid rgba(255, 255, 255, 1); */
  /* margin: 5px; */
  cursor: pointer;
  /* transition: background-color 0.1s ease-out; */
}
