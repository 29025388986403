div .dismiss {
    font-weight: bold;
  color: rgb(186, 97, 255);
  border-color: rgb(186, 97, 255);
  background-color: rgb(255, 255, 255);
  &:hover {
    border-color: rgba(186, 97, 255, 0.5);
    color: rgba(186, 97, 255, 0.5);
  }
}
div .confirm {
    font-weight: bold;
  background-color: rgb(186, 97, 255);
  width: 130px;
  transition: width 0.1s ease;
  &:hover {
    background-color: rgba(186, 97, 255, 0.8);
    color: rgb(255, 255, 255);
  }
}

div .image {
  /* padding: 24px; */
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  /* aspect-ratio: 0.5; */
  /* aspect-ratio: 1; */
}

