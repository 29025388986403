.tagscontainer {
  display: flex;
  flex-direction: row;
  float: right;
  margin-right: 40px;
}

.tag {
  /* position: absolute; */
  border-radius: 12px 12px 0px 0px;
  box-sizing: border-box;

  /* background-color: rgb(60, 60, 60); */
  /* border: 1px solid rgb(51 50 59); */
  border: 1px solid rgb(106 104 117);

  padding-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 3px;
  background-color: rgba(24, 20, 35, 0.5);
  width: min-content;
  /* position: absolute; */

  cursor: pointer;
  margin-right: 4px;

  transform: translate(0px, 3px);
  border-bottom: none;
  width: 96px;
  text-align: center;
}
.tag.selected {
  z-index: 2;
  font-weight: bold;
  /* border-bottom: 1px solid white; */
  border-bottom: 5px solid rgba(40, 34, 54, 1);
}
