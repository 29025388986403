.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */
  /* padding-top: 56px; */
  /* padding-bottom: 56px; */
  /* z-index: 10; */
  /* height: 400px; */
  overflow-y: scroll;
  /* height: fit-content; */
  height: calc(100vh - 40px);
  margin-top: 0px;
  background: linear-gradient(to bottom, rgb(59, 50, 75), rgb(12, 10, 14));
}
.scrollcontainer {
  width: 100%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 56px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}

.pagearea {
  position: relative;
  max-width: 1200px;
}
.pagecontainer {
  min-height: 800px;
  padding-top: 32px; /*20 + menuheight*/
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 56px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;

  /* button {
    padding: 2px 8px;
    height: 32px;
  } */

  border-radius: 16px;
  box-sizing: border-box;
  /* background-color: rgb(60, 60, 60); */
  /* border: 1px solid rgb(51 50 59); */
  border: 1px solid rgb(106 104 117);

  background-color: rgba(24, 20, 35, 0.5);
  /* frost effect */
  /* tint */
  /* background: linear-gradient(to bottom, rgb(80, 64, 98), rgb(61, 55, 74)); */
  /* backdrop-filter: saturate(180%) blur(20px); */
}

div .fieldlabel {
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
  align-self: flex-start;
}

.input {
  background-color: white;
  border-radius: 4px;
  padding: 4px 16px;
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

.textarea {
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  transition: height 0.1s ease;

  /* background-color: transparent; */
  /* color: rgb(255, 255, 255); */
}
.contenttextarea {
  border-radius: 4px;
  /* padding: 12px 16px; */
  font-size: 16px;
  resize: none;
  width: 100%;
  /* box-sizing: border-box; */
  transition: height 0.1s ease;

  background-color: transparent;
  color: rgb(255, 255, 255);
  border: none;
  padding: 8px;
  margin-left: 0x;
  margin-bottom: 36px;
  box-sizing: border-box;
}
.notes {
  padding: 12px 16px;
  font-size: 16px;
  resize: none;
  width: 100%;
  box-sizing: border-box;

  border: 0;
  border-radius: 3px;

  min-height: 100px;

  /* box-shadow: 3px 8px 6px rgba(0, 0, 0, 1); */
  box-shadow:
    1px 1px 1px rgba(30, 30, 30, 0.3),
    1px 1px 3px rgba(207, 203, 143, 0.9),
    2px 2px 1px rgba(60, 60, 60, 0.5),
    3px 3px 1px rgba(207, 203, 143, 0.9),
    4px 4px 1px rgba(60, 60, 60, 0.5),
    4px 5px 1px rgba(207, 203, 143, 0.9),
    inset 2px 0px 4px rgba(60, 60, 60, 0.3),
    inset -3px -3px 3px rgba(60, 60, 60, 0.3);
  overflow: hidden;
  transition:
    height 0.1s ease,
    box-shadow 0.5s ease;
  background: linear-gradient(rgb(249, 239, 207), rgb(247, 233, 173));
  /* background: linear-gradient(to bottom, #b89dff, #937be6); */
  /* background: linear-gradient(to bottom, rgb(187, 161, 198), rgb(150, 113, 158)); */

  /* background: linear-gradient(#f9ffdf, #f7d9ed); */
}
.comments {
  color: rgb(208, 219, 208);
}
/* .notes:focus {
  outline: 3px rgb(247, 233, 173) solid !important;
} */

.topgrid {
  /* padding-left: 1%; */
  /* width: 99%; */
  display: grid;
  grid-template-columns: 1fr 120px 26%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  box-sizing: border-box;
}
.topgridcolumn1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;
  /* align-items: center; */
  button {
    width: 300px;
  }
}
.topgridcolumn1 > div {
  padding-left: 0.5vw;
  box-sizing: border-box;
}
.outcomecontainer {
  margin-bottom: 8px;
}

.centercontainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.topgridcolumn3 {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;

  /* margin-bottom: -90px; */
  /* align-items: start; */
}
.grid {
  margin-left: 0.5vw;
  width: 99%;
  display: grid;
  grid-template-columns: 1fr 120px 27%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  /* box-sizing: border-box; */

  div {
    padding: 2px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.mainsection:after {
  content: '';
  width: 100%; /* Full width */
  height: 1px; /* Divider height */
  background-color: #ccc; /* Divider color */
  margin-bottom: 20px; /* Spacing between elements */
}

div .button {
  background-color: rgb(245, 245, 245); /* Green */
  border: none;
  color: rgb(30, 30, 30);
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  font-weight: bold;
  /* width: auto; */

  &:hover {
    background-color: rgb(255, 255, 255, 1);
    color: rgb(30, 30, 30);
  }
  &:disabled {
    background-color: rgb(128, 128, 128, 0.5);
    color: rgb(150, 150, 150);
    cursor: not-allowed;
  }
}
div .aibutton {
  padding: 4px 12px;
  font-weight: bold;
  text-transform: none;
  color: rgb(255, 255, 255);
  background-color: rgb(112, 58, 152);
  font-size: 16px;
  &:hover {
    background-color: rgb(186, 97, 255);
    color: rgb(255, 255, 255);
  }
}

div .tooltip {
  padding-top: 8px;
  /* padding: 20px ; */
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);

  max-width: 90%;

  /* backdrop-filter: saturate(100%) blur(30px) !important; */
  filter: drop-shadow(0px 0px 8px rgb(186, 97, 255));
  z-index: 100;
  /* border is set from props */
  border-radius: 16px;
  /* overflow: visible; */
  /* background-color: transparent; */
  /* background-color: green; */
}
div .tooltipcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* background-color: red; */
  /* height: 300px; */
  border-radius: 16px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -8px;
  margin-bottom: -20px;
  /* padding: 20px; */
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 28px;
  /* background-color: rgba(255, 0, 0, 0.3); */
}

div .tooltipbutton {
  width: 120px;
  align-self: center;
  /* background-color: ; */
}
.tooltipcontent {
}

/* .tooltiparrow {
  color: green !important;
}
.tooltipbody {
  background-color: green !important;
}
.tooltiptextcontainer {
  padding: 8px;
} */

/* .metanotes {
  grid-column: 3 / -1;
} */

.centerrow {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  button {
    width: 400px;
  }
}
