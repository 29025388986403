.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */
  /* padding-top: 56px; */
  /* padding-bottom: 56px; */
  /* z-index: 10; */
  /* height: 400px; */
  overflow-y: scroll;
  /* height: fit-content; */
  height: calc(100vh - 40px);
  margin-top: 0px;
  background: linear-gradient(to bottom, rgb(59, 50, 75), rgb(12, 10, 14));
}
.scrollcontainer {
  width: 100%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 56px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
}
.pagearea {
  position: relative;
  width: 100%;
  max-width: 1072px;
}
.pagecontainer {
  min-height: 800px;
  padding-top: 32px; /*20 + menuheight*/
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 56px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;

  /* button {
    padding: 2px 8px;
    height: 32px;
  } */

  border-radius: 16px;
  box-sizing: border-box;
  /* background-color: rgb(60, 60, 60); */
  /* border: 1px solid rgb(51 50 59); */
  border: 1px solid rgb(106 104 117);

  background-color: rgba(24, 20, 35, 0.5);
  /* frost effect */
  /* tint */
  /* background: linear-gradient(to bottom, rgb(80, 64, 98), rgb(61, 55, 74)); */
  /* backdrop-filter: saturate(180%) blur(20px); */
}

div .fieldlabel {
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
  align-self: flex-start;
}

.input {
  background-color: white;
  border-radius: 4px;
  padding: 4px 16px;
  height: 42px;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
}

/* .notes:focus {
  outline: 3px rgb(247, 233, 173) solid !important;
} */

.topgrid {
  /* padding-left: 1%; */
  /* width: 99%; */
  display: grid;
  grid-template-columns: 1fr 120px 26%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  box-sizing: border-box;
}

.topgridcolumn1 > div {
  padding-left: 0.5vw;
  box-sizing: border-box;
}
.outcomecontainer {
  margin-bottom: 8px;
}

.centercontainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.topgridcolumn3 {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: start;

  /* margin-bottom: -90px; */
  /* align-items: start; */
}
.grid {
  margin-left: 0.5vw;
  width: 99%;
  display: grid;
  grid-template-columns: 1fr 120px 27%; /* Three rows: 100px, 200px, and the remaining space divided equally */
  grid-auto-flow: row;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  /* box-sizing: border-box; */

  div {
    padding: 2px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.mainsection:after {
  content: '';
  width: 100%; /* Full width */
  height: 1px; /* Divider height */
  background-color: #ccc; /* Divider color */
  margin-bottom: 20px; /* Spacing between elements */
}

div .button {
  background-color: rgb(245, 245, 245); /* Green */
  border: none;
  color: rgb(30, 30, 30);
  padding: 4px 12px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  font-weight: bold;
  /* width: 100px; */
  /* width: auto; */

  &:hover {
    background-color: rgb(255, 255, 255, 1);
    color: rgb(30, 30, 30);
  }
  &:disabled {
    background-color: rgb(128, 128, 128, 0.5);
    color: rgb(150, 150, 150);
    cursor: not-allowed;
  }
}
div .aibutton {
  padding: 4px 12px;
  font-weight: bold;
  text-transform: none;
  color: rgb(255, 255, 255);
  background-color: rgb(112, 58, 152);
  font-size: 16px;
  &:hover {
    background-color: rgb(186, 97, 255);
    color: rgb(255, 255, 255);
  }
}

div .tooltip {
  padding-top: 8px;
  /* padding: 20px ; */
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);

  max-width: 90%;

  /* backdrop-filter: saturate(100%) blur(30px) !important; */
  filter: drop-shadow(0px 0px 8px rgb(186, 97, 255));
  z-index: 100;
  /* border is set from props */
  border-radius: 16px;
  /* overflow: visible; */
  /* background-color: transparent; */
  /* background-color: green; */
}
div .tooltipcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* background-color: red; */
  /* height: 300px; */
  border-radius: 16px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: -8px;
  margin-bottom: -20px;
  /* padding: 20px; */
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 28px;
  /* background-color: rgba(255, 0, 0, 0.3); */
}

div .tooltipbutton {
  width: 120px;
  align-self: center;
  /* background-color: ; */
}
.tooltipcontent {
}

/* .tooltiparrow {
  color: green !important;
}
.tooltipbody {
  background-color: green !important;
}
.tooltiptextcontainer {
  padding: 8px;
} */

/* .metanotes {
  grid-column: 3 / -1;
} */

.centerrow {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  button {
    width: 400px;
  }
}

.answerscontainer {
  box-sizing: border-box;
  padding: 8px;
  /* border-radius: 8px; */
  /* border: 1px solid rgba(255, 255, 255, 0.5); */

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 16px; */
  /* margin-bottom: 16px; */
}
.questionscontainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.questioncontainer {
  margin-top: 24px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  background-color: rgba(70, 59, 87, 0.6);
}
.questionhead {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
}

.textarea {
  /* font-weight: semi-b; */
  font-size: 16px;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  transition: all 0.1s;
  resize: none;
  box-sizing: border-box;
}

.answer {
  display: flex;
  flex-direction: row;
}

.score {
  font-weight: bold;
  font-size: 32px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit {
  width: 100px;
}

.divider {
  height: 1px;
  background-color: #ccc;
  margin: 5px 0;
}

.commentcontainer {
  /* margin-top: 16px; */
  /* margin-bottom: 16px; */
  color: rgba(220, 220, 220, 1);
}

.markingschemecontainer {
  position: relative;
  /* display: grid; */
  /* flex-direction: row; */
}
.markingscheme {
  background: transparent;
  color: rgba(220, 220, 220, 1);
  padding-right: 40px;
}
.questiontext {
  font-size: 18px;
}
.answertext {
  font-size: 18px;
  /* font-style: italic; */
}

div .addanswer {
  /* margin-left: 200px; */
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1) !important;
  align-self: center;
  margin-top: 8px;
  width: 240px;
}
div .iconbutton {
  color: rgba(255, 255, 255, 1);
  /* width: 140px; */
  cursor: pointer;
}
.genmarkingscheme {
  box-sizing: border-box;
  position: absolute;
  top: 4px;
  right: 0px;
  /* z-index: 1000; */
  /* height: 0px; */
  /* top: 8px; */
}

.answercontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* background-color: rgba(228, 228, 228, 0.1); */

  /* backdrop-filter: blur(8px); */
  margin-left: 120px;
  /* padding-left: 200px; */
  /* background-color: purple; */
  box-sizing: border-box;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 36px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}
.removeanswer {
  position: absolute;
  bottom: 0px;
  right: 8px;
  opacity: 0;
  transition: opacity 0.3s;
}

.answercontainer:hover .removeanswer {
  opacity: 1;
}

.removequestion {
  right: 0px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
}
.questioncontainer:hover .removequestion {
  opacity: 1;
}

div .addquestion {
  background-color: transparent;

  border: 1px solid rgba(255, 255, 255, 1) !important;

  &:hover {
    background-color: transparent;
    color: rgb(255, 255, 255);
  }
  &:active {
    background-color: transparent;
    color: rgb(255, 255, 255);
  }
}
